import {
  // getAccountNftCollections,
  getAccountNfts,
  // getMyNftsCount,
  // getNftCount,
  // getNftransactions,
  // getNfts,
  // getNftsFromCollection,
  getTransactions,
  getAccount
} from "../config/apiEndPoints";
import { Nft } from "../types/nft";
import { Transaction } from "../types/transaction";
// import { Collection } from "../types/collection";
// import mergeWith from "lodash.mergewith";
//import { forEach } from "lodash";
import cloneDeep from "lodash.clonedeep";
import { tokensEntitlementGet } from "./tokenomics";

// for minting
import { getParamFromUrl } from "../utils/getParamFromUrl";
import { Address } from '@elrondnetwork/erdjs/out/address';
import { ContractFunction } from '@elrondnetwork/erdjs/out/smartcontracts/function';
import { U32Value } from '@elrondnetwork/erdjs/out/smartcontracts/typesystem/numerical';
import { TokenPayment } from '@elrondnetwork/erdjs/out/tokenPayment';
import BigNumber from 'bignumber.js';
import { ContractCallPayloadBuilder } from "@elrondnetwork/erdjs/out/smartcontracts/transactionPayloadBuilders";
import { TransactionWatcher } from '@elrondnetwork/erdjs/out/transactionWatcher';
import { ApiNetworkProvider } from '@elrondnetwork/erdjs-network-providers/out/apiNetworkProvider';
import { WalletProvider } from '@elrondnetwork/erdjs-web-wallet-provider/out/walletProvider';
import { networkConfig, chainType, DAPP_INIT_ROUTE } from '../config/network';
import { Transaction as MVXTransaction } from '@elrondnetwork/erdjs/out/transaction';
import router from 'next/router';

const multversxAPIDomain = process.env.NEXT_PUBLIC_MULTIVERSX_API_ENDPOINT;
const env = process.env.NEXT_PUBLIC_MULTIVERSX_CHAIN;

// TEMP until proper env
import { epcCollectionDetails_prod } from '../config/collections_prod.config'
import { epcCollectionDetails_dev } from '../config/collections_dev.config'

const epcCollectionDetails = env === 'mainnet' 
                                ? epcCollectionDetails_prod 
                                : epcCollectionDetails_dev;
// END: TEMP until proper env


const store = {}; // keep the data cached, so don't have to keep going back to the blockchain


const validCollections = Object.keys(epcCollectionDetails);

export const mintDisplayColls = mintDisplayCollsGet(epcCollectionDetails);

// return an array in mintDisplayOrder of the collections from epcCollectionDetails.
// If a collection does not have a mintDisplayOrder set, it will not make the list
function mintDisplayCollsGet(epcCollectionDetails) {
  const mintDisplayColls = [];
  for (const epcCollObjName in epcCollectionDetails) {
    const epcCollObj = epcCollectionDetails[epcCollObjName];
    const isToBeDisplayed = epcCollObj.hasOwnProperty('mintDisplayOrder');
    if (!isToBeDisplayed) continue;
    const epcCollObjToDisplay = cloneDeep(epcCollObj);
    epcCollObjToDisplay.name = epcCollObjName; // eg 'VALIDATOR-01f4d4'
    mintDisplayColls.push(epcCollObjToDisplay);
  }

  return mintDisplayColls.sort((a, b) => a.mintDisplayOrder - b.mintDisplayOrder);
}

export const selectedDefaultEPCColl = defaultMintCollGet(mintDisplayColls);

function defaultMintCollGet(mintDisplayColls) {
  for (const epcCollObj of mintDisplayColls) {
    if (epcCollObj.defaultSelected) return epcCollObj;
  }
}

// for now we merge EPCs (epcs) and transactions from all accounts, but
// tokens are split by account
export async function walletsDataGet(addresses) {
  const result = {
    addrsSorted: []
    , epcs: []
    , transactions: []
    , tokens: {}
    , account: {}
  };
  let walletData;

  for (const addr of addresses) {
    if (store[addr]) {
      walletData = store[addr];
    } else {
      walletData = await walletDataGet(addr);
      store[addr] = walletData;
    }
    // result.epcs = [...result.epcs, ...walletData.epcs];
    result.addrsSorted = addresses; // this is used for rendering wallet contents everywhere in dashboard so that the same sort order is maintained everywhere
    result.epcs[addr] = walletData.epcs;
    // result.transactions = mergeWith(result.transactions, walletData.transactions, unionArraysDuringMerge);
    result.transactions = [...result.transactions, ...walletData.transactions];
    result.tokens[addr] = walletData.tokens;
    result.account[addr] = walletData.account;
  }
  return result;
  // return cloneDeep(store);
}

async function walletDataGet(address) {

  let res = await fetch(getAccountNfts(address, {}));
  let epcs: Nft[] = await res.json();

  // Filter out all except valid tokens: https://github.com/rescuaearth/core/issues/40
  const walletDisplayColls = validCollections;

  epcs = epcs.filter(obj => {
    return walletDisplayColls.some(epcColl => obj.collection === epcColl);
  });

  // // From https://stackoverflow.com/a/27997088/2036135
  // function unionArraysDuringMerge(objValue, srcValue) {
  //   if (Array.isArray(objValue)) {
  //       return Array.from(new Set([...objValue, ...srcValue]));
  //   }
  // }

  {/* get transactions */ }
  let transactions = await fetch(getTransactions(address, 'sender'));
  let transactionsData: Transaction[] = await transactions.json();

  transactions = await fetch(getTransactions(address, 'receiver'));
  transactionsData.push(...(await transactions.json()));

  // Sort the transactions by date
  transactionsData.sort((a, b) => {
    return b.timestamp - a.timestamp;
  });

  // transactionsData.forEach((transaction: Transaction) => {
  //   if (transaction.receiver == address) {
  //     //console.log('receiver');
  //   } else {
  //     //console.log('sender');
  //   }
  // });

  // {/* get my collections */ }
  // let collections = await fetch(
  //   getAccountNftCollections(process.env.NEXT_PUBLIC_OFFICIAL_ACCOUNT_ADDRESS, "")
  // );
  // let collectionsData: Collection[] = await collections.json();

  let tokens = tokensEntitlementGet(epcs, epcCollectionDetails);

  // // get rescua epcs
  // let rescuaNfts = await fetch(getNfts({ collection: "RESCUAN-ebc02f" }));
  // let rescuaNftsData: Nft[] = await rescuaNfts.json();

  // // get validator epcs
  // let validatorNfts = await fetch(getNfts({ collection: "VALIDATOR-01f4d4" }));
  // let validatorNftsData: Nft[] = await validatorNfts.json();

  // // get governor epcs
  // let governorNfts = await fetch(getNfts({ collection: "GOVERNOR-7e1689" }));
  // let governorNftsData: Nft[] = await governorNfts.json();

  let account = await fetch(getAccount(address));
  let accountData = await account.json();

  return {
    epcs,
    transactions: transactionsData,
    // collections: collectionsData,
    tokens: tokens,
    account: accountData
    // rescuaNfts: rescuaNftsData,
    // validatorNfts: validatorNftsData,
    // governorNfts: governorNftsData
  }
}

// START: MINTING

const mintTxBaseGasLimit = Number(process.env.NEXT_PUBLIC_MINT_BASE_GAS_LIMIT);
const mintFunctionName = process.env.NEXT_PUBLIC_MINT_FUNCTION_NAME;

let provider = new WalletProvider(
  `${networkConfig[chainType].walletAddress}${DAPP_INIT_ROUTE}`
);

let apiNetworkProvider = new ApiNetworkProvider(
  process.env.NEXT_PUBLIC_ELROND_API,
  {
    timeout: Number(networkConfig[chainType].apiTimeout)
  }
);

export async function newEPCMint(receiver, purchWalletAddr) {

  const account = await accountGet(purchWalletAddr);
  const tokensAmount = 1;
  const tokens = tokensAmount || 1;
  const func = new ContractFunction(mintFunctionName);
  const gasLimit =
    mintTxBaseGasLimit + (mintTxBaseGasLimit / 2) * (tokensAmount - 1);
  const args = [new U32Value(tokens)];
  const res = await fetch("/api/elrond/vm-values/int", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ funcName: "getNftPrice", scAddress: receiver })
  });
  const rawData = await res.json();
  const mainData = rawData.data;
  const totalPayment = new BigNumber(Number(mainData.data)).times(tokens);
  const value = TokenPayment.egldFromBigInteger(totalPayment);
  const data = new ContractCallPayloadBuilder()
    .setFunction(func)
    .setArgs(args)
    .build();
  const tx = new MVXTransaction({
    data,
    gasLimit,
    value,
    receiver: new Address(receiver),
    sender: new Address(account.address),
    chainID: networkConfig[chainType].shortId
  });
  tx.setNonce(account.nonce);
  await provider.signTransaction(tx);

};

async function accountGet(address) {
  const remoteAccount = await fetch(
    `/api/elrond/accounts/${address}`
  );
  const account = await remoteAccount.json();
  return account;
}

// import { ContractQueryRunner, Interaction } from "@multiversx/sdk-nestjs";

// export async function testSC () {
//   const CONTRACT_ADDRESS = "erd1qqqqqqqqqqqqqpgqpk57xrzuwq6dma525jp5q0kx76y07pqs3zuqrv2h4a";
//   const cRunner = new ContractQueryRunner(new ApiNetworkProvider(this.apiConfigService.getApiUrl()));

//   const contract = await this.contractLoader.getContract(CONTRACT_ADDRESS);

//   const interaction: Interaction = contract.methodsExplicit.getTotalLockedAssetSupply([]);

//   const queryResponse = await cRunner.runQuery(contract, interaction);
//   console.log("testSC", queryResponse);
// }

export async function prepareSend(setPending, showToast) {
  // testSC();
  const purchWalletAddr = getParamFromUrl('sender[0]');
  const nonceStr = getParamFromUrl('nonce[0]');
  const nonce = parseInt(nonceStr);
  const nonceIsNum = Number.isInteger(nonce);
  console.log("prepareSend: ", purchWalletAddr, nonce, nonceIsNum);
  if (!purchWalletAddr || !nonceIsNum) return; // TODO: show error

  const txs = provider.getTransactionsFromWalletUrl();
  console.log(txs, "txs");
  const transactionObj = txs?.[0];
  if (transactionObj) {
    transactionObj.data = Buffer.from(transactionObj.data).toString("base64");
    const transaction = MVXTransaction.fromPlainObject(transactionObj);
    //const account = await accountGet(purchWalletAddr);
    transaction.setNonce(nonce);
    console.log(transaction);
    send(transaction, setPending, showToast);
  }
}

async function send(transaction, setPending, showToast) {
  window.history.replaceState(null, "", window.location.pathname); // removes query string values?
  try {
    setPending(true);
    await apiNetworkProvider.sendTransaction(transaction);
    var res = await postSendTx(transaction);
    console.log("transaction done", transaction, res);
    showToast("success", "EPC minted successfully");
  } catch (e) {
    console.log(e);
  } finally {
    console.log("finally");
    setPending(false);
    // delay 3s
    await new Promise((resolve) => setTimeout(routeChangeToDashboardPage, 3000));
  }
}

async function postSendTx(transaction) {
  const transactionWatcher = new TransactionWatcher(apiNetworkProvider);
  await transactionWatcher.awaitCompleted(transaction);
}

function routeChangeToDashboardPage() {
  router.push("/dashboard");
}

// END: MINTING


// START: SMART CONTRACT QUERIES 

export async function collectionEPCsLeftGet(address) {
  const postData = {
      "scAddress": address
    , "funcName": "getTotalTokensLeft" 
    , "args": []
  };
  const fetchOptions = {
      method: 'POST'
    , headers: { 'Content-Type': 'application/json' }
    , body: JSON.stringify(postData)
  };
  let res = await fetch(multversxAPIDomain + '/vm-values/int', fetchOptions);
  const resultObj = await res.json();
  return resultObj?.data?.data || resultObj.code;
}

// END: SMART CONTRACT QUERIES 
