const epcCollectionDetails_prod = {
    "RESGENGS-4a563c": {
        type: "RESCUAN"
      , tokens: { sng: 100, ssp: 100, scp: 100, sup: 100, nzp: 100, gnz: 25000 }
      , creator: "erd1qqqqqqqqqqqqqpgqpa409p6vt726hq2pxprd4qn2qglxhruyqkxsf5v2g6"
      , mintDisplayOrder: 2
      , titleImageFilename: "rescuan-typo.svg"
      , cardImageFilename: "rescuanFrame.png"
      , mainColour: "#DF7C3C"
      , mintButtonVisibility: "visible"
      , defaultSelected: true
      , currentlyAvailable: 223
      , priceEGold: 13.5
      , tokenSupply: 40000
      , priceUSD: 500
  }
  , "VALGENGS-904b54": {
        type: "VALIDATOR"
      , tokens: { sng: 1000, ssp: 1000, scp: 1000, sup: 1000, nzp: 1000, gnz: 250000 }
      , creator: "erd1qqqqqqqqqqqqqpgqhemv3z0edq7kdp9c3r7ca6vas0u0jlv4qkxsljmvwz"
      , mintDisplayOrder: 3
      , titleImageFilename: "validator-typo.svg"
      , cardImageFilename: "validatorFrame.png"
      , mainColour: "#282F66"
      , mintButtonVisibility: "visible"
      , currentlyAvailable: 22
      , priceEGold: 134
      , tokenSupply: 2000
      , priceUSD: 5000
  }
  , "GOVERNOR-7e1689": {
        type: "GOVERNOR"
      , tokens: { sng: 100000, ssp: 100000, scp: 100000, sup: 100000, nzp: 100000, gnz: 25000000 }
      , creator: "erd1qqqqqqqqqqqqqpgqks9na6mq5ue0gq0lpepczcavrkc5c6gc3zuqfwgm9d"
      , mintDisplayOrder: 1
      , titleImageFilename: "governor-typo.svg"
      , cardImageFilename: "governorFrame.png"
      , mainColour: "#DD515A"
      , mintButtonVisibility: "hidden"
      , currentlyAvailable: 0
      , priceEGold: 11500
      , tokenSupply: 10
      , priceUSD: 500000
  }
};

export { epcCollectionDetails_prod };