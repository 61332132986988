
const numFormat = new Intl.NumberFormat();
import CssFilterConverter from 'css-filter-converter'; // overcomes this https://medium.com/@union_io/swapping-fill-color-on-image-tag-svgs-using-css-filters-fa4818bf7ec6

export function Token({tokenDetails, displayColourHex = null}) {

  // convert from hex color to filter which will style an SVG added in an <img/>
  const filterString = displayColourHex
          ? CssFilterConverter.hexToFilter(displayColourHex).color
          : null;

  return (

      <div> {/* This div is actually necessary to keep the children stacked vertically under the flex, otherwise they are laid out horizonally */}
        <img src={ '/assets/images/epc/' + tokenDetails.imageFilename }
                  alt={ tokenDetails.tokenName }
                  className="mx-auto w-4/12" style={{ filter: filterString }} />
        <div className="pb-10">
          <h3 className="poppins-semi-bold text-center mt-3 text-lg">
            { numFormat.format(tokenDetails.tokenAmount) }
          </h3>
          <h4 className="poppins-semi-bold text-center mt-3 text-sm">
            { tokenDetails.tokenName }
          </h4>
        </div>
      </div>

  );

}
