// export const DEFAULT_MIN_GAS_LIMIT = 50_000;

// export const DAPP_CONFIG_ENDPOINT = "/dapp/config";
export const DAPP_INIT_ROUTE = "/dapp/init";

export const chainType = process.env.NEXT_PUBLIC_MULTIVERSX_CHAIN;

export const networkConfig = {
  devnet: {
    id: chainType,
    shortId: "D",
    name: "Devnet",
    // egldLabel: "xEGLD",
    // egldDenomination: "18",
    // decimals: "4",
    // gasPerDataByte: "1500",
    // walletConnectDeepLink:
    //   "https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://maiar.com/",
    // walletConnectBridgeAddresses: ["https://bridge.walletconnect.org"],
    walletAddress: "https://" + process.env.NEXT_PUBLIC_MULTIVERSX_WALLET_ADDR,
    // apiAddress: process.env.NEXT_PUBLIC_ELROND_API,
    // explorerAddress: "https://devnet-explorer.elrond.com",
    apiTimeout: "4000",
  },

  testnet: {
    id: chainType,
    shortId: "T",
    name: "Testnet",
    // egldLabel: "xEGLD",
    // egldDenomination: "18",
    // decimals: "4",
    // gasPerDataByte: "1500",
    // walletConnectDeepLink:
    //   "https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://maiar.com/",
    // walletConnectBridgeAddresses: ["https://bridge.walletconnect.org"],
    walletAddress: "https://" + process.env.NEXT_PUBLIC_MULTIVERSX_WALLET_ADDR,
    // apiAddress: process.env.NEXT_PUBLIC_ELROND_API,
    // explorerAddress: "https://testnet-explorer.elrond.com",
    apiTimeout: "4000",
  },

  mainnet: {
    id: chainType,
    shortId: "1",
    name: "Mainnet",
    // egldLabel: "EGLD",
    // egldDenomination: "18",
    // decimals: "4",
    // gasPerDataByte: "1500",
    // walletConnectDeepLink:
    //   "https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://maiar.com/",
    // walletConnectBridgeAddresses: ["https://bridge.walletconnect.org"],
    walletAddress: "https://" + process.env.NEXT_PUBLIC_MULTIVERSX_WALLET_ADDR,
    // apiAddress: process.env.NEXT_PUBLIC_ELROND_API,
    // explorerAddress: "https://explorer.elrond.com",
    apiTimeout: "4000",
  },
};
