const multversxAPIDomain = process.env.NEXT_PUBLIC_MULTIVERSX_API_ENDPOINT;


export const getAccount = (address: string) =>
  `${multversxAPIDomain}/accounts/${address}`;


export const getNftCollections = () =>
  `${multversxAPIDomain}/accounts/collections`;


export const getNftCollectionsCount = () =>
  `${multversxAPIDomain}/collections/count`;


export const getAccountNftCollections = (walletAddress: string, search: String = "") => {
  let url = `${multversxAPIDomain}/accounts/${walletAddress}/collections?size=10`;
  if (search) {
    url += `&search=${search}`;
  }
  return url;
}


export const getAccountNfts = (
  address: String,
  { size = null, creator = null, collection = null }
) => {
  let base = `${multversxAPIDomain}/accounts/${address}/nfts?`;
  if (size) {
    base += `size=${size}`;
  }
  if (creator) {
    base += `&creator=${creator}`;
  }
  if (collection) {
    base += `&collection=${collection}`;
  }
  // console.log(base)
  return base;
};


export const getNfts = ({ collection = null }) => {
  let base = `${multversxAPIDomain}/nfts?`;
  if (collection) {
    base += `collection=${collection}`;
  }
  return base;
};


export const getNftsFromCollection = (collection: String) =>
  `${multversxAPIDomain}/collections/${collection}/nfts`;


export const getNft = (identifier: string) =>
  `${multversxAPIDomain}/nfts/${identifier}`;


export const getNftCount = () => `${multversxAPIDomain}/nfts/count`;


export const getTransactions = (address: String, type: String) =>
  `${multversxAPIDomain}/transactions` + `?${type}=${address}`;


export const getMyTokens = (address: String) =>
  `${multversxAPIDomain}/accounts/${address}/tokens`;


export const getMyNftsCount = (address: String, collection: String) =>
  `${multversxAPIDomain}/accounts/${address}/nfts/count?collection=${collection}`;


export const getNftransactions = (identifier: String) =>
  `${multversxAPIDomain}/nfts/${identifier}/transactions`;