import cloneDeep from "lodash.clonedeep";
// import { getMyNftsCount } from "../config/apiEndPoints";
//import { objOfObjsMapToArray } from "../utils/dataUtils";

// TODO: choose/create proper svgs for each token
export const tokenDetails = {
    sng: { tokenCode: "SNG", tokenName: "SNG", imageFilename: "token-sng.svg" }
  , ssp: { tokenCode: "SSP", tokenName: "SSP", imageFilename: "token-ssp.svg" }
  , scp: { tokenCode: "SCP", tokenName: "SCP", imageFilename: "token-scp.svg" }
  , sup: { tokenCode: "SUP", tokenName: "SUP", imageFilename: "token-sup.svg" }
  , nzp: { tokenCode: "NZP", tokenName: "NZP", imageFilename: "token-nzm.svg" }
  , gnz: { tokenCode: "GNZ", tokenName: "GNZ", imageFilename: "token-gnz.svg" }
}

// purpose is to create an object of objects in the same stucture as tokenDetails
// for a given account, and add to each child object a SUM of the tokens
// for that account (called tokenAmount) based on all the EPCs in that account.
// The mapping of tokens per EPC comes in via epcCollectionDetails.
export function tokensEntitlementGet(epcs, epcCollectionDetails) {

  const tokens = cloneDeep(tokenDetails);

  for(const epc of epcs) {
    const validCollectionDetail = epcCollectionDetails[epc.collection];
    const tokensList = Object.keys(validCollectionDetail.tokens);
    for(const token of tokensList) {
      if (!tokens[token].tokenAmount) tokens[token].tokenAmount = 0;
      tokens[token].tokenAmount += validCollectionDetail.tokens[token];
    }
  }
  return tokens;

}
